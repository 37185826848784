import React from "react"
import { Col, Container, Row } from "reactstrap"

const Divider = ({fluid}) => (
  <Container tag="section" fluid={fluid}>
    <Row>
      <Col xs="12">
        <hr className="divider"></hr>
      </Col>
    </Row>
  </Container>
)

export default Divider
