import data from "./nizza.json";
import slugify from "slugify";

function generateSlug(oldString: string) {
  let modified = oldString
    .toLowerCase()
    .replace("ä", "ae")
    .replace("ö", "oe")
    .replace("ü", "ue")
    .replace("ß", "ss");

  const nicht = modified.indexOf(", nicht");
  if (nicht !== -1) {
    modified = modified.substring(0, nicht);
  }

  return slugify(modified, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: /\[.*\]|\(.*\)/g, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: true, // strip special characters except replacement, defaults to `false`
  }).replace(/^(.{70}[^-]*).*/, "$1");
}

function stripUmlaute(str: string) {
  return str
    .toLowerCase()
    .replace("ä", "ae")
    .replace("ö", "oe")
    .replace("ü", "ue")
    .replace("ß", "ss")
}

function sortAndCapitalize(arr: any[]) {
  return arr.sort((a, b) => {
    return stripUmlaute(a.description) > stripUmlaute(b.description) ? 1 : -1
  }).map(item => {
    item.id = String(item.id);
    item.description = item.description.charAt(0).toUpperCase() + item.description.substring(1);
    item.slug = generateSlug(item.description);
    return item;
  });
}

export const getClassItems = (classNumber: number) => {
  if (!classNumber) {
    return []
  }
  console.log(classNumber, typeof classNumber)
  const results = data.filter((item) => item.class === classNumber);
  return sortAndCapitalize(results);
};

export const searchByString = (searchString: string) => {
  if (!searchString) {
    return []
  }
  const results = data.filter(
    (item) =>
      item.description.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
  )
  return sortAndCapitalize(results);
};

export const searchById = (id: string) => {
  if (!id) {
    return []
  }
  const results = data.filter((item) => String(item.id) === id);
  return sortAndCapitalize(results);
};
