import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Link } from "gatsby"

const AboutNizza = () => (
  <Container tag="section">
    <Row>
      <Col xs="12">
        <h2>Was ist eine Nizza-Klasse?</h2>
      </Col>
      <Col xs="12" lg="6">
        <p>
          Die internationale Markenklassifikation beinhaltet 45 Produktklassen.
          Sie klassifiziert Produkte und Dienstleistungen nach einem von der
          Weltorganisation für geistiges Eigentum (WIPO) definierten System. Die
          34 Warenklassen unterscheiden sich grundsätzlich durch die enthaltenen
          Produkt- und Materialgruppen. Die 11 Dienstleistungsklassen hingegen
          v. a. hinsichtlich der Sparte und Branche.
        </p>
        <p>
          Möchtest du eine Marke für dein eigenes Produkt anmelden, musst du
          zunächst die <strong>Nizza-Klasse deines Produkts herausfinden</strong>. Denn eine
          Marken kann immer nur für ausgewählte und bei der Anmeldung angegebene
          Markenklassen eingetragen werden. Ein auf T-Shirts spezialisiertes
          Modelabel würde vermutlich seine Marke <i>NiceFashion</i> für die
          Klasse 25 (Bekleidung) anmelden. Ein IT-Dienstleister mit Kunden in
          der Modebranche könnte in diesem Beispiel die gleiche
          Markenbezeichnung für die Markenanmeldung in Klasse 42
          (Softwaredienstleistung) nutzen.
        </p>
      </Col>
      <Col xs="12" lg="6">
        <p>
          <strong>Aber Achtung</strong>: Nicht immer ist die Klassifikation juristisch eindeutig und trennscharf abgrenzbar wie in
          unserem Beispiel. So könnte die Neuanmeldung für <i>NiceFashion</i> in
          Klasse 42 (Softwaredienstleistung) eine gleichnamige, bereits
          eingetragene Marke eines Mitbewerbers in Klasse 9 (Softwareprodukt)
          verletzen.
        </p>
        <p>
          Nutze unsere <strong>Volltext-Suchfunktion</strong> für Produkte und
          Dienstleistungen. So kannst du auch bisher nicht beachtete Klassen
          aufspüren, die für die Markenanmeldung deines Produkts in Frage
          kommen.
          </p>
        <p>
          Die WIPO aktualisiert die Nizza-Klassifizierung mittlerweile jährlich.
          Unsere Suchfunktion basiert auf den aktuellsten <Link to="/#disclaimer" title="Hinweis">Veröffentlichungen</Link> des
          Deutschen Patent- und Markenamtes (März 2020).
        </p>
      </Col>
    </Row>
  </Container>
)

export default AboutNizza
