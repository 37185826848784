import { Link } from "gatsby";
import React from "react";
import { getClassItems, searchByString } from "../../search/search";

export function getById(data: any[], id: string) {
  const results = data.filter(item => item.id === id)
  return results.length === 1 ? results : null
}

export function getSearchResultsAsync(searchString: string) {
  if (!searchString || searchString.length < 3) {
    return Promise.resolve([]);
  }

  return Promise.resolve(searchByString(searchString))
}

export function getClassAsync(classNumber: number) {
  if (!classNumber) {
    return Promise.resolve([]);
  }
  return Promise.resolve(getClassItems(classNumber));
}
export function getSearchResults(
  data: any[],
  searchString: string,
  returnAll: boolean
) {
  if (searchString === null) {
    return []
  }
  if (searchString.length < 3) {
    if (returnAll) {
      return sortProperly(data)
    } else {
      return []
    }
  }
  return sortProperly(
    data.filter(
      item =>
        item.description.toLowerCase().indexOf(searchString.toLowerCase()) !==
        -1
    )
  )
}

function sortProperly(arr: any[]) {
  return arr.sort(function(a, b) {
    function transform(str: string) {
      return str
        .toLowerCase()
        .replace("ä", "ae")
        .replace("ö", "oe")
        .replace("ü", "ue")
        .replace("ß", "ss")
    }
    return transform(a.description) > transform(b.description) ? 1 : -1
  })
}

export function getHighlightedString(
  searchString: string,
  item: any,
  link?: boolean
): React.ReactElement {
  const index = item.description
    .toLowerCase()
    .indexOf(searchString.toLowerCase())

  let highlightedString = <span className="title">{item.description}</span>

  if (searchString.length >= 3) {
    if (index >= 0) {
      highlightedString = (
        <span className="title">
          {item.description.substring(0, index)}
          <strong className="marked">
            {item.description.substring(index, index + searchString.length)}
          </strong>
          {item.description.substring(index + searchString.length)}
        </span>
      )
    }
  }

  return (
    <>
      {link ? (
        <Link to={`/klasse/${item.class}/${item.slug}`}>
          {highlightedString}
          <span className="circle-number"><span>{item.class}</span></span>
        </Link>
      ) : (
        <>
          <Link to={`/klasse/${item.class}/${item.slug}`}>
            {highlightedString}
          </Link>
        </>
      )}
    </>
  )
}